import React from "react";
import Layout from "../../components/Layout";
import NavTwo from "../../components/NavTwo";
import Footer from "../../components/Footer";
import Subscribe from "../../components/Subscribe";
import { APP_NAME } from "../../config/constants";
import BlogHome from "../../components/BlogHome";
import BlogDetails from "../../components/BlogDetails";
const StoryPage = (props) => {
  console.log('StoryPage props::::', props)
  return (
    <Layout pageTitle={`Story | ${APP_NAME}`}>
      <NavTwo />
      <div className="thm-gray-bg111">     
        {
          (props.id === 'list') ? (
            <BlogHome />
          ) : (
            <BlogDetails {...props} />
          )
        }
        <Subscribe />
        <Footer />
      </div>
    </Layout>
  )
}


export default StoryPage;
